@use '@/styles/utils/mixins.scss' as *;

.pageContentWrapper {
  .pageContentContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3.75rem;
    @include tab() {
      grid-template-columns: repeat(1, 1fr);
      gap: 0.5rem;
    }
    &.smallContent {
      grid-template-columns: repeat(2, 1fr);
      @include tab() {
        grid-template-columns: repeat(1, 1fr);
      }
      .pageContentSection {
        grid-column: span 1;
      }
    }
  }
  .pageContentHeadingSection {
    width: 100%;
  }
  .pageContentSection {
    padding-top: 0.75rem;
    grid-column: span 2;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;

    .pageContentTextWrapper {
      display: flex;
      justify-content: space-between;
      gap: 4rem;

      @include tab() {
        flex-direction: column;
      }
    }

    @include tab() {
      grid-column: span 1;
    }
    .pageContentBtn {
      width: fit-content;
      @include mob() {
        width: 100%;
      }
    }
    .pageContentDetailsImage {
      width: 23.125rem;
      height: 11.25rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0 0 auto;

      img {
        max-width: 100%;
        width: auto;
        height: auto;
        max-height: 11.25rem;
      }
    }
  }
  .pageContentDetails {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 1.5rem;
      font-weight: 500;
      &:not(:first-child) {
        margin-top: 2.5rem;
      }
    }
    h1 {
      font-size: 6rem;
    }
    h2 {
      font-size: 4.5rem;
    }
    h3 {
      font-size: 3rem;
    }
    h4 {
      font-size: 2.125rem;
    }
    h5 {
      font-size: 1.5rem;
      @include mob() {
        font-size: 1rem;
      }
    }
    h6 {
      font-size: 1.5rem;
      @include mob() {
        font-size: 1rem;
      }
    }

    p {
      margin-bottom: 1rem;
    }
    ul,
    ol {
      padding-left: 1.5rem;
      margin-bottom: 1rem;
    }
    a {
      color: $primary;
      &:hover,
      &:focus,
      &:active {
        color: $primaryAlt;
      }
    }
  }
  .buttonWrapper {
    display: flex;
    gap: 2.5rem;

    @include mob() {
      flex-direction: column;
    }
  }
  .mobileImageContainer {
    margin-top: 3.75rem;
    width: 100%;
    height: auto;
    max-width: 23.125rem;
    max-height: 11.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100%;
      width: auto;
      height: auto;
      max-height: 11.25rem;
    }
  }
}
